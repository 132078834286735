@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html,
body,
#root {
	height: 100%;
}

body {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.page {
	display: flex;
	flex-direction: column;
	margin-top: 118px;
}

.title {
	font-size: 44px;
	font-weight: 700;
	line-height: 52px;
	margin-bottom: 45px;
}

#container-rating {
	display: flex;
	position: relative;
	align-items: center;
	margin-bottom: 59px;
}

#score {
	margin: 0;
	font-weight: 400;
	font-size: 44px;
	margin-right: 24px;
}

.container-stars {
	width: max-content;
	display: flex;
}

.container-stars svg {
	margin-right: 5px;
}

.container-stars.empty svg path {
	fill: #e0e0e0;
}

.star {
	fill: #e0e0e0;
}

.golden path,
.rated path {
	fill: #ffcd69;
}

.btn {
	font-weight: 700;
	color: #797874;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	padding: 14px 10px 13px;
	width: 141px;
	font-size: 17px;
	line-height: 20.76px;
	cursor: pointer;
}

#btn-review {
	position: absolute;
	right: 0;
}

#list-reviews {
	border-top: 1px solid #b9b9b9;
	padding-top: 41px;
	max-width: 580px;
}

#title-list-reviews {
	font-size: 28px;
	font-weight: 700;
	line-height: 33px;
	margin-bottom: 29px;
}

.container-reviews {
	display: flex;
	align-items: center;
}

.score-review {
	margin-left: 29px;
	font-weight: 700;
}

.text-review {
	word-wrap: break-word;
	color: #858585;
}

ul {
	padding-left: 0;
}

/* overlay */
#overlay {
	display: none;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.6);
}

/* modal */

#modal {
	background-color: #fff;
	padding: 48px 53px 46px;
	border-radius: 20px;
	max-width: 680px;
}

#modal form {
	display: flex;
	flex-direction: column;
}

#modal .container-stars {
	cursor: pointer;
}

#modal .container-stars,
textarea,
label {
	margin-bottom: 40px;
}

#modal button {
	width: 218px;
}

label {
	font-size: 24px;
	line-height: 28px;
}

input {
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	line-height: 23px;
	border: none;
	outline: none;
	max-height: 23px;
	margin-bottom: 40px;
}
